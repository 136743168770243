import React from 'react';
import {Route, Switch, BrowserRouter as Router, } from 'react-router-dom';
import '../src/styles/hltheraImport.scss';

const Loader = React.lazy(() => import('./components/Loader/loader'));
const TermsAndCondition = React.lazy(() => import('./components/TermsAndCondition/termsAndCondition'));
const MainPage = React.lazy(() => import('./components/MainPage/mainPage'));
const ComingSoon = React.lazy(() => import('./components/ComingSoon/comingSoon'));
const ThanksPage = React.lazy(() => import('./components/ThanksPage/thanksPage'));



function App() {
  return (
    <Router>
      <React.Suspense fallback={true}>
         <Switch>
              {/* <ThanksPage/> */}
              <Route exact path="/" name="Coming Soon" render={props => <ComingSoon {...props}/>} />
              {/* <Route exact path="/termsCondition" name="Terms And Condition" render={props => <TermsAndCondition {...props}/>} /> */}
              {/* <Route exact path="/page" name="Page" render={props => <MainPage {...props}/>} />
              <Route path="/" name="Home" render={props => <Loader {...props}/>} /> */}
        </Switch> 
      </React.Suspense>
    </Router>

  );
}

export default App;
